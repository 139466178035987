div.banner-bg {
    background-image: url("../../../assets/Congratulations.svg");
    background-size: contain;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    /* background-repeat: no-repeat; */
  }
  
  .welcome {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #202223;
  }
  .letsConnect {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6d7175;
  }
  
  .topbarSetting {
    padding: 0 2rem;
    display: flex;
    gap: 20px;
  }
  @media screen and (max-width:765px) {
    .topbarSetting{
      padding: 0px  1rem 10px 0;
      justify-content:end;
      align-items: center;
      width: 100%;
    } 
  }
  
  .statusBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px;
    gap: 12px;
    margin: auto;
    width: min-content;
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px rgba(6, 44, 82, 0.1),
      0px 2px 16px rgba(33, 43, 54, 0.08);
    border-radius: 58px;
  }
  .statusbar-text {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #202223;
  }
  .orderText {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #101828;
  }
  .last10days {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #6d7175;
  }
  
  .order-received-synced-digit {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #101828;
  }
  .networth {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #202223;
    margin-top: 75px;
  }
  
  .congratulationsText {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6d7175;
    width: 300px;
  }
  
  .order-receivedOrder-syncedOrder-digit {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #101828;
  }
  .receivedAmount-syncedAmount-count{
    font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  
  /* or 16px */
  display: flex;
  align-items: center;
  
  color: #6D7175;
  }
  