/* borderRadius: "25px",
textAlign: "center",
color: "#fff",
padding:"3px 10px",
width:"maxcontent", */

.integration{
border-radius: 25px;
text-align: center;
color: #fff;
padding: 3px 10px;
width: fit-content;
}