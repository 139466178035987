.transcript_container {
  display: block;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 32px;
  background: #f1f8f5;
  box-shadow: inset 0px -1px 0px #e1e3e5;
}
.transcript_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  opacity: 0.9;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  margin-top: 20px;
}
.transcript_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #202223;
}
