.wrapper {
  padding: 0 30px;
}
.globalContainer {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 50%;
}
.message_container {
  display: flex;
  flex-direction: column;
  height: 200px;
  background: #ffffff;
  border: 1px solid #aeb4b9;
  /* box-shadow: inset 0px 1px 0px #898f94; */
  border-radius: 4px;
}

@media screen and (max-width: 700px) {
  .globalContainer {
    width:100%;
  }
}

.action_container {
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
}

textarea {
  border: 0;
  height: inherit;
  resize: none;
}

textarea:focus {
  outline: none !important;
  border-color: #aeb4b9;
}

.icon_container{
    padding:5px 10px;
    border-radius: 4px;
}

.active_icon{
    background: var(--p-decorative-three-surface);
}
.heading{
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #101828;
}

.sub_heading{
    font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

/* identical to box height, or 143% */

/* Text/Default */
color: #202223;
}