.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 32px;
  height: 56px;
  background: white !important;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  
}
