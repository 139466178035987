.dropdown-list {
  display: flex;
  list-style-type: none;
  padding: 5px;
  justify-content: flex-start;
  align-content: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

ul {
  padding: 10px;
}

.dropDown-label {
  display: flex;
  gap: 10px;
  align-content: flex-end;
  justify-content: center;
}
