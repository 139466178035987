.textFieldContainer {
  width: 28%;
}


@media screen and (max-width: 1000px) {
    .textFieldContainer{
      width: 40%;
    }
  }  

  @media screen and (max-width: 500px){
    .textFieldContainer{
        width: 100%;
    }
  }