.gridContainer {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-gap: 20px;
}

.gridContainer > div {
  text-align: center;
  padding: 20px 0;
}

.courierHeading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #202223;
}

.couriertext {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6d7175;
  margin-top: 15px;
  width: 42%;
}

@media screen and (max-width: 900px) {
  .couriertext{
    width: 100%;
  }
}

.subHeading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #202223;
  margin-top: 25px;
}

.sideBarOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: max-content;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6d7175;
  list-style: none;
  padding: 0;
  cursor: pointer;
}

.p {
  padding: 15px 30px;
}

.active {
  width: 3px;
  height: 28px;
  background: #008060;
  border-radius: 0px 4px 4px 0px;
}

.orderContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  text-align: center;
  background: #ebf9fc;
  border-radius: 4px;
}

.addPaypalBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  border: 1px solid #0070ba;
  background: #0070ba;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;

  color: #ffffff;
  border-radius: 4px;
}

.userName {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #202223;
}

.userID {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #6d7175;
}

.default {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 120% */

  text-align: center;

  /* Text/Default */

  color: #202223;
}

.cancelSubscription {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Text/Default */

  color: #202223;
}

.activationDate {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Text/Default */

  color: #202223;
}
/* subscription */

.planName {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #202223;
}
.planValue {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #202223;
}
.planDiscription {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #202223;
}

.firstdiv {
  width: 17%;
}

.abc {
  width: 90%;
}

.pl-20 {
  padding-left: 20px;
}

.card_padding_left_20{
  padding-left: 20px;
}

.card_padding_right_20{
  padding-right: 20px;
}

@media screen and (max-width:1200px) {
  .card_padding_right_20{
    padding-right: 0px;
  }
  .card_padding_left_20{
    padding-left: 0px;
  }
  
}

.pr-20 {
  padding-right: 20px;
}
.p-20 {
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .abc {
    width: 100%;
  }
  .maindiv {
    flex-wrap: wrap;
  }
  .firstdiv {
    width: 100%;
  }
  .cards {
    flex-wrap: wrap;
  }
}

.w-50 {
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .w-50 {
    width: 100%;
  }
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-left: 23px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  color: #6d7175;
}

.activeTab {
  /* width: 1px; */
  /* height: 28px;   */
  border-left: 3px solid #008060;
  border-radius: 0px 4px 4px 0px;
  color: #008060;
}

.navItem {
  padding: 10px 0 10px 5px;
  cursor: pointer;
  /* border:2px solid red */
}

.courierFilters {
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  gap: 10px;
}
