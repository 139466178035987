.card_alignment {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 20px;
}
.card_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #101828;
}

.user_key {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #202223;
}

.user_value {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6d7175;
}

.font-14px {
  font-size: 14px !important;
}
/* Account Setting css */

.secondaryText {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Text/Subdued */
  color: #6d7175;
}

.cardWidth{
  width: 500px;
}

.cardStyle {
  display: flex;
  gap: 20px;
  padding: 0px 30px;
  justify-content: space-between;
}

@media screen and (max-width: 1134px) {
.cardStyle{
  flex-wrap: wrap;
}

.cardWidth{
  width: 100%;
}
}

.linkContainer{
  color:white !important;
  text-decoration: none;
}
.accountSettingCard{
  width: 30%;
}
@media screen and (max-width: 1000px) {
  .accountSettingCard{
    width: 100%;
  }
}  