.icon_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  background: #f2f7fe;
  border-radius: 280px;
}

.integration_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #202223;
}

.plans_text {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6d7175;
}

.amount{
    color: #202223 !important;
}