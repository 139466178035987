.card_alignment {
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 20px;
}
.card_title{
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
color: #101828;
}

.dashboardCardWidth{
  width: 18%;
}

@media screen and (max-width: 1670px) {
  .dashboardCardWidth{
    width: 290px;
  }
}