@font-face {
  font-family: "SF pro Text";
  src: local("FontsFree-Net-SFProText-Regular"),
    url("../font/FontsFree-Net-SFProText-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SF Pro Display";
  src: local("FontsFree-Net-SFProDisplay-Regular"),
    url("../font/FontsFree-Net-SFProDisplay-Regular.ttf");
}

.wrapper {
  padding: 0 30px;
}

.title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #101828;
}

.dashboard_title {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #202223;
}

.table_heading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #202223;
}

.d-flex {
  display: flex !important;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-20 {
  gap: 20px;
}

.flex-col {
  flex-direction: column !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center{
  justify-content: center;
}

.align-item-end {
  align-items: end !important;
}

.align-item-center {
  align-items: center;
}

.flex_wrap {
  flex-wrap: wrap;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mr-10{
  margin-right: 10px;
}

.mt-10{
  margin-top: 10px;
}

.Polaris-DataTable__Cell--numeric {
  text-align: left;
}

.p-15{
  padding: 15px;
}

.pl-20 {
  padding-left: 22px;
}

.pr-20 {
  padding-right: 22px;
}
.pb-10{
  padding-bottom: 10px;
}

.pl-2 {
  padding-left: 2rem;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-15 {
  gap: 15px;
}

.h-100vh {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: end;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.aligns-Items-center {
  align-items: center !important;
}

.capitalize {
  text-transform: capitalize !important;
}
.mt-5{
  margin-top: 5px;
}

.cursor_none{
  cursor: not-allowed;
}

.cursor_pointer{
  cursor: pointer;
}
/* Onboarding */
.connectWithPaypal {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  background: #0070ba;
  border: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.skip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  background: #f6f6f7;
  border: 1px solid #babfc3;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  cursor: pointer;
}

.WelcomeToTrackipal {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #202223;
}

.letConnect {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #6d7175;
}

.termAndCondition {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #202223;
}

.setting {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #202223;
}

.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableCell:first-child {
  position: unset;
}

.Polaris-IndexTable__Table--sticky
  .Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  position: unset;
}

.table-data {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
  text-transform: capitalize !important;
  text-decoration: none;
}

.table-data-secondary {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6d7175;
  text-transform: uppercase !important;
}

.customBadge {
  border-radius: 25px;
  text-align: center;
  /* color: #fff; */
  padding: 3px 10px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.Polaris-ButtonGroup--segmented {
  flex-wrap: wrap !important;
}

/* fix-font-family for tabbar */
.Polaris-Text--semibold {
  font-family: "SF pro Text" !important;
}

.warningHeading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #b98900;
}

.warningDiscription {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #6d7175;
}
.text-white {
  color: #ffffff !important;
}
.bg-white{
  background-color: #ffffff;
}
.Polaris-Link {
  text-decoration: none;
}
@media screen and (max-width:765px) {
  .tab_bar_wrap{
    flex-wrap: wrap;
  }
}

.dateContainerField {
  width: 250px;
  padding: 8px;
  border-radius: 2px;
  color: #8c9196;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #d2d5d8;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

@media screen and (max-width:350px) {
  .dateContainerField{
    width: 200px;
  }
}

.text-captilize{
  text-transform: capitalize;
}

.Polaris-Layout{
  overflow-x:hidden;
}

@media screen and (max-width: 400px) {
  .mobileviewgap {
    gap: 20px !important;
  }
}
