.resyncOrderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  padding: 12px;
  background: #fff4f4;
  border: 1px solid #e0b3b2;
  border-radius: 8px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.clearFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 52px;
  height: 36px;
  background: #f6f6f7;
  border: 1px solid #babfc3;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.filterConatiner {
  color: #202223;
  align-items: center;
padding: 5px 20px;
}

.filterConatiner:hover {
  background-color: #E0F8EE;
  border-radius: 8px;
  color: #007a5c;
}

.activeFilter{
  background-color: #E0F8EE;
  border-radius: 8px;
  color: #007a5c; 
}

.filters {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.resyncText {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #202223;
}

.inputContainer {
  display: flex;
  background: #f1f2f3;
  border-radius: 4px;
  width: 70%;
  gap: 10px;
  padding: 12px;
}

@media screen and (max-width: 1400px) {
  .inputContainer {
    width: 60%;
    gap: 10px;
  }
}
@media screen and (max-width: 1100px) {
  .inputContainer {
    width: 100%;
    gap: 10px;
  }
}
input {
  width: 100%;
  border: none;
  background: #f1f2f3;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202223;
}
input:focus {
  border: none;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 20px;
  gap: 10px;
  /* width: 400px;
    height: 264px; */
  top: 30%;
  background: #fff;
  z-index: 101;
  border-radius: 8px;
}

#popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -2;
}

#popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: -1;
  box-shadow: inset 0px -1px 0px #e1e3e5;
  border-radius: 8px 8px 0px 0px;
}

.popupHeading {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #202223;
  text-align: center;
}
.popupDetails {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #202223;
}

.paginationContainer {
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.cursor_p {
  cursor: pointer;
}
